<template>
  <div class="build-container">
    <div class="ant-glass-background build-info">
      <div class="build-header">
        ANT
      </div>
      <div class="build-content">
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr>
                <td>{{$t('system.buildInfo.RlNr')}}</td>
                <td>{{ releaseNr }}</td>
              </tr>
              <tr>
                <td>{{$t('system.buildInfo.RlDate')}}</td>
                <td>{{ releaseDate }}</td>
              </tr>
              <tr>
                <td>{{$t('system.buildInfo.CV')}}</td>
                <td>{{ commitVersion }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </div>
  </div>
</template>

<script>
import buildInfo from "@/assets/buildinfo.json"

export default {
  name: "Build",
  computed: {
    releaseNr() {
      return buildInfo.ReleaseNr
    },
    releaseDate() {
      return buildInfo.ReleaseDate
    },
    commitVersion() {
      return buildInfo.Commitversion
    }
  }
}
</script>

<style scoped lang="scss">
.build-container {
  display: flex;
  align-items: center;
  justify-content: center;

  .build-info {
    border-top: solid 3px var(--v-primary-base);
    padding: 24px 64px;

    .build-header {
      text-align: center;
      font-size: 60px;
      color: var(--v-primary-base);
    }

    .build-content {
      display: flex;
      flex-direction: column;

    }

  }
}

</style>
